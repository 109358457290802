import React from 'react';
import {BlocksRenderer} from '@strapi/blocks-react-renderer';
import PropTypes from 'prop-types';
import Card from '../../application/ui-elements/Card';

const InfoCard = ({hoveredPhase, selectedPhase, selectedSubphase, topics, selectedPhaseTitle, isMobile, closeCard, index}) => {
    const sliceNumber = selectedPhase.id === 'mastery' ? 2 : 3;
    const subtopicName = selectedSubphase?.id?.slice(0, selectedSubphase.id.length - sliceNumber) ?? null;
    const topicName = subtopicName?.split('-')[0];
    const getTopic = topics?.find(topic => topic.id === topicName)?.subTopics;
    const subtopicTitle = getTopic?.find(topic => topic.id === subtopicName)?.name;
    const title = subtopicTitle ?? selectedPhase.name;
    const description = selectedSubphase?.text ?? selectedPhaseTitle;

    return (
        <Card variant={hoveredPhase} isPhase={true} isSubphase={!!selectedSubphase} index={index}>
            <div className="is-flex is-align-items-center is-justify-content-flex-start is-relative">
                <img
                    alt={hoveredPhase.toLowerCase()}
                    src={`${process.env.PUBLIC_URL}/assets/icons/content-view.svg`}
                />
                <p className="ml-1 is-size-5 has-text-weight-semibold">
                    {title}
                </p>
                {isMobile && (
                    <div
                        onTouchStart={closeCard ? () => closeCard('') : undefined}
                        className="sski-info-card__icon-wraper is-absolute is-flex is-align-items-center is-justify-content-center"
                    >
                        <img
                            className="sski-info-card__close-icon"
                            alt="close icon"
                            src={`${process.env.PUBLIC_URL}/assets/icons/close.svg`}
                        />
                    </div>
                )}
            </div>
            <p className={`${isMobile ? 'py-3' : 'p-3'} sski-info-card__description`}>
                {description && <BlocksRenderer content={description} />}
            </p>
        </Card>
    );
};

InfoCard.propTypes = {
    hoveredPhase: PropTypes.string.isRequired,
    selectedPhase: PropTypes.object.isRequired,
    selectedSubphase: PropTypes.object.isRequired,
    topics: PropTypes.array.isRequired,
    selectedPhaseTitle: PropTypes.string.isRequired,
};

export default InfoCard;
