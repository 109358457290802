import React from 'react';
import './DataRow.scss';

const DataRow = props => {
    const {children} = props;
    return (
        <div className="sski-c-data-row">
            {children}
        </div>
    );
};

export default DataRow;
