import routePaths from '../routePaths';

const routesOrderingMapper = {
    CATEGORIES: {
        currentPathName: routePaths.CATEGORIES,
        previousPathName: routePaths.DASHBOARD,
    },
};

export default routesOrderingMapper;
