/**
 * Resource endpoints
 */
export default {
    PHASES: 'phases/:phaseId?',
    SUB_PHASES: 'sub-phases/:subPhaseId?',
    ELEMENTS: 'elements/:elementId?',
    SPORTS: 'sports/:sportId?',
    USERS: 'users/:userId?',
    USER_TYPES: 'user-types',
    TOPICS: 'topics/:topicId?',
    SUB_TOPICS: 'sub-topics/:subTopicId?',
    LINKS: 'links/:linkId?',
};
