import {select, fork, take, put} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import {subPhaseTypes} from '@swiss-ski/sski-entity-types';
import * as dashboardActionTypes from '../dashboardActionTypes';

const loadIntroductionContents = function* loadIntroductionContents() {
    const {serviceManager} = yield select(state => state.application);
    const ftemStrapiService = serviceManager.loadService('ftemStrapiService');

    const filterParamsString = Object.keys(subPhaseTypes).map((key, index) => {
        return `filters[$or][${index}][itemKey][$eq]=introduction-home-${Object.values(subPhaseTypes[key])[0].toLowerCase()}`;
    }).join('&');

    yield fork(
        fetchRequest,
        dashboardActionTypes.FETCH_INTRODUCTION_CONTENTS_REQUEST,
        ftemStrapiService.getElements,
        {filterParamsString},
    );
    const responseAction = yield take([
        dashboardActionTypes.FETCH_INTRODUCTION_CONTENTS_REQUEST_SUCCEEDED,
        dashboardActionTypes.FETCH_INTRODUCTION_CONTENTS_REQUEST_FAILED,
    ]);
    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {elementDTOs} = response;
        yield put({
            type: dashboardActionTypes.STORE_INTRODUCTION_CONTENTS,
            payload: {
                elementDTOs,
            },
        });
    }
};

export default loadIntroductionContents;
