import React, {useState, useRef} from 'react';
import {kebabCase} from 'change-case';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import {ReactSVG} from 'react-svg';
import './SidebarNavigation.scss';
import routePaths from '../../routePaths';
import useOutsideClick from '../../utils/useOutsideClick';

const SidebarNavigation = props => {
    const {sports, history, match, isMobile} = props;
    const {sportId} = match?.params;
    const [hoveredElement, setHoveredElement] = useState(null);
    const handleNavigation = sportId => {
        history.replace(resolveRoute(routePaths.DASHBOARD, {sportId}));
    };

    const Logo = () => {
        return (
            <img
                src={`${process.env.PUBLIC_URL}/assets/sski-logo.svg`}
                alt="sski-logo"
                className={`sski-c-sidebar-navigation__logo${isMobile ? '-mobile' : ''}`}
            />
        );
    };

    const AllSports = () => {
        return (sports.map(sport => {
            const imageURL = `${process.env.PUBLIC_URL}/assets/sports/${kebabCase(sport.id)}.svg`;
            return (
                <div
                    key={sport.id}
                    onMouseEnter={() => !isMobile && setHoveredElement(sport.id)}
                    onMouseLeave={() => !isMobile && setHoveredElement(null)}
                    className={`sski-c-sidebar-navigation__sport ${isMobile ? 'dropdown-item is-justify-content-left is-flex' : ''}`}
                    onClick={() => !isMobile && handleNavigation(sport.id)}
                    onTouchStart={() => isMobile && handleNavigation(sport.id)}
                >
                    <ReactSVG
                        className={`image is-40x40 is-align-self-center p-1 sski-c-sidebar-navigation__sport-icon ${
                            sportId === sport.id
                                ? 'sski-c-sidebar-navigation__sport-icon--active'
                                : ''}
                                ${isMobile ? '' : 'mb-3'}
                                `}
                        alt={sport.id}
                        src={imageURL}
                    />
                    {isMobile && <span className="sski-c-sidebar-navigation__title">{sport.title}</span>}
                    {(hoveredElement === sport.id && !isMobile) && (
                    <span className="sski-c-sidebar-navigation__sport-tooltip">
                        {sport.title}
                    </span>
                    )}
                </div>
            );
        })
        );
    };

    const MobileDropdown = () => {
        const [isActive, setIsActive] = useState(false);
        const dropDownRef = useRef(null);
        useOutsideClick(dropDownRef, () => setIsActive(false));
        return (
            <div
                className={`dropdown sski-c-sidebar-navigation__dropdown ${isActive ? 'is-active' : ''}`}
                ref={dropDownRef}
            >
                <div className="sski-c-sidebar-navigation-dropdown__trigger dropdown-trigger" onClick={() => setIsActive(!isActive)}>
                    <button
                        className="button is-flex is-justify-content-center is-flex-direction-column
                                   sski-c-sidebar-navigation-dropdown_button p-0"
                        aria-haspopup="true"
                        aria-controls="nav-menu"
                    >
                        <div className="mt-4">
                            <Logo />
                        </div>
                        <div className="sski-c-sidebar-navigation__sport-icon--active
                                        image is-40x40 is-align-self-center p-1
                                        sski-c-sidebar-navigation__sport-icon mt-2 mb-2"
                        >
                            <ReactSVG
                                alt="selected-sport"
                                src={`${process.env.PUBLIC_URL}/assets/sports/${sportId}.svg`}
                            />
                        </div>
                        <img
                            className="image mb-2"
                            alt="drop-down-arrow"
                            src={`${process.env.PUBLIC_URL}/assets/icons/arrow-down.svg`}
                        />
                    </button>
                </div>
                {/* {isSubPhase && ( */}
                {/* <div className="sski-c-dashboard-screen__mobile-subphase-handler"> */}
                {/*    <ReactSVG */}
                {/*        alt="close icon" */}
                {/*        src={`${process.env.PUBLIC_URL}/assets/icons/close-subphase.svg`} */}
                {/*        onClick={() => setClickedPhase('')} */}
                {/*    /> */}
                {/* </div> */}
                {/* )} */}
                <div className="dropdown-menu sski-c-sidebar-navigation__dropdown-menu pt-0" id="nav-menu" role="menu">
                    <div className="dropdown-content">
                        <div className="ml-3 mt-2">
                            <Logo />
                        </div>
                        <AllSports />
                        <div className="dropdown-item is-flex is-justify-content-center">
                            <img
                                onClick={() => setIsActive(false)}
                                alt="drop-down-arrow"
                                src={`${process.env.PUBLIC_URL}/assets/icons/arrow-up.svg`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const DesktopNavigation = () => {
        return (
            <div className="sski-c-sidebar-navigation__background pt-6 has-background-white">
                <Logo />
                <div className="is-flex is-flex-direction-column is-flex-wrap-wrap is-justify-content-center pt-6">
                    <AllSports />
                </div>
                <div className="sski-c-sidebar-navigation__squares-container">
                    <div className="sski-c-sidebar-navigation__square_dark" />
                    <div className="sski-c-sidebar-navigation__square_light" />
                </div>
            </div>
        );
    };


    if (isMobile) {
        return <MobileDropdown />;
    }
    return <DesktopNavigation />;
};

SidebarNavigation.propTypes = {
    sports: PropTypes.array,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isMobile: PropTypes.bool,
};

SidebarNavigation.defaultProps = {
    sports: [],
};

const mapStateToProps = state => {
    return {
        sports: state.dashboard.sports,
    };
};

export default withRouter(connect(mapStateToProps)(SidebarNavigation));
