import React, {useContext, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import './DataCell.scss';
import DataTableContext from './DataTableContext';

const DataCell = props => {
    const {children, isTitle, isMiddleRowCell, isRightEndCell, position, colorType, columnName} = props;
    const {isContentCentered, isContentBold} = props;
    const dataCellRef = useRef(null);
    const {selectedColumn} = useContext(DataTableContext);

    useEffect(() => {
        if (!dataCellRef?.current) return;

        const links = dataCellRef.current.querySelectorAll('a');
        if (links.length > 0) {
            links.forEach(link => {
                link.target = '_blank';
            });
        }
    }, [dataCellRef]);

    return (
        <div
            ref={dataCellRef}
            className={`sski-c-data-cell sski-c-data-cell--border-radius-${position} ${
                isTitle ? `pt-2 pb-1 sski-c-data-cell--background-${colorType} has-text-white has-text-centered is-size-5 has-text-weight-medium` : ''
            } ${
                isRightEndCell ? 'mr-1' : ''
            } ${
                isMiddleRowCell ? 'sski-c-data-cell--border-bottom' : ''
            } ${
                !isMiddleRowCell && !isTitle ? `sski-c-data-cell--box-shadow-${colorType}` : ''
            } ${
                !isTitle ? 'p-2 pt-4 sski-c-data-cell--min-height' : ''
            } ${
                isContentCentered ? 'has-text-centered' : ''
            } ${
                isContentBold ? 'has-text-weight-bold is-size-5' : ''
            } ${
                selectedColumn === columnName ? `sski-c-data-cell--background-selected-${colorType}` : ''
            }`}
        >
            {children}
        </div>
    );
};

DataCell.propTypes = {
    isTitle: PropTypes.bool,
    isRightEndCell: PropTypes.bool,
    isMiddleRowCell: PropTypes.bool,
    position: PropTypes.string,
    colorType: PropTypes.string,
    isContentCentered: PropTypes.bool,
    isContentBold: PropTypes.bool,
    columnName: PropTypes.string,
};

DataCell.defaultProps = {
    isTitle: false,
    isRightEndCell: false,
    isMiddleRowCell: false,
    position: '',
    colorType: '',
    isContentCentered: false,
    isContentBold: false,
    columnName: '',
};

export default DataCell;
