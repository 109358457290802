import React, {useContext, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import DataTableContext from './DataTableContext';
import DataTableNavigationBar from './DataTableNavigationBar';
import {MobileDevice} from '../../index';

const cellWidth = 290;

const DataTableProvider = ({children, columnNames}) => {
    const [scrollPosition, setScrollPosition] = useState({left: 0, top: 0});
    const [selectedColumn, setSelectedColumn] = useState(null);
    const tableRef = useRef(null);
    const {isMobile} = useContext(MobileDevice);

    const handleFocusColumn = (index, columnName) => {
        setSelectedColumn(columnName);
        tableRef.current.scrollLeft = (index + 1) * cellWidth - cellWidth / 3 - window.innerWidth / 2;
    };

    return (
        <DataTableContext.Provider
            value={{
                scrollPosition,
                selectedColumn,
                columnNames,
                onColumnFocus: handleFocusColumn,
            }}
        >
            <div
                ref={tableRef}
                className="sski-c-categories-screen__tables_container"
                onScroll={() => setScrollPosition({
                    left: tableRef.current.scrollLeft,
                    top: tableRef.current.scrollTop,
                })}
            >
                {children}
            </div>
            {!isMobile ? <DataTableNavigationBar /> : null}
        </DataTableContext.Provider>
    );
};

DataTableProvider.propTypes = {
    columnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DataTableProvider;
