import React from 'react';
import {ReactSVG} from 'react-svg';
import './MobileAnimation.scss';
import {phaseNamesMapper} from '../../../utils/ftemConstants';

const MobileAnimation = ({onHover, phaseSelected}) => {
    const pillarAction = name => {
        onHover(phaseNamesMapper[name]);
    };
    const getImgSrc = `${process.env.PUBLIC_URL}/assets/animation/${phaseSelected}-active.svg`;

    return (
        <div className="sski-mobileanim-container is-flex is-justify-content-center is-relative">
            <div className="sski-mobileanim-container__frame is-relative">
                <ReactSVG
                    src={`${process.env.PUBLIC_URL}/assets/animation/FTEM.svg`}
                    className="sski-mobileanim-container_image image is-absolute"
                />
                {phaseSelected && (
                <ReactSVG
                    src={getImgSrc}
                    className="sski-mobileanim-container_image image is-absolute"
                />
                )}
                <div onClick={() => pillarAction('F')} className="sski-mobileanim-container__f_phase sski-mobileanim-container__trigger" />
                <div onClick={() => pillarAction('T')} className="sski-mobileanim-container__t_phase sski-mobileanim-container__trigger" />
                <div onClick={() => pillarAction('E')} className="sski-mobileanim-container__e_phase sski-mobileanim-container__trigger" />
                <div onClick={() => pillarAction('M')} className="sski-mobileanim-container__m_phase sski-mobileanim-container__trigger" />
            </div>
        </div>
    );
};

export default MobileAnimation;
