import {all, fork} from 'redux-saga/effects';
import fetchTopicContents from './sagas/fetchTopicContents';

const dashboardWatcher = function* dashboardWatcher() {
    yield all([
        fork(fetchTopicContents),
    ]);
};

export default dashboardWatcher;
