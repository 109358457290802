import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as applicationActionTypes from '../applicationActionTypes';
import './LinkViewModal.scss';
import linkOutIcon from '../../../public/assets/icons/link-out.svg';
import useOutsideClick from '../../utils/useOutsideClick';

const LinkViewModal = props => {
    const {location, closeModal, links} = props;
    const {linkType, linkId} = location?.query ?? {};
    const link = links.find(link => link.id === linkId) ?? {links: 'https://snowsports.flink.host/s/Cc3DstWh/', title: 'Mission Swiss-Ski Langlauf'};
    const modalRef = useRef(null);

    useOutsideClick(modalRef, closeModal);

    if (!link) return null;

    return (
        <div className="modal is-active">
            <div className="modal-background" />
            <div className="sski-c-link-modal">
                <button
                    type="button"
                    className="sski-c-link-modal__close-button"
                    aria-label="close"
                    onClick={closeModal}
                >
                    <img alt="exit" src={`${process.env.PUBLIC_URL}/assets/icons/exit-circle.svg`} />
                </button>
                <div ref={modalRef} className="sski-c-link-modal__card modal-card">
                    <header className={`sski-c-link-modal__head sski-c-link-modal__head--${linkType} modal-card-head`}>
                        <p className={`sski-c-link-modal__head_title--${linkType} modal-card-title has-text-centered`}>
                            {link.title}
                        </p>
                        <a
                            href={link.links}
                            target="_blank"
                            className="sski-c-link-modal__link-out"
                            onClick={closeModal}
                            rel="noreferrer"
                        >
                            <svg
                                className={`sski-c-link-modal__link-out_icon sski-c-link-modal__link-out_icon--${linkType}`}
                                viewBox={linkOutIcon.viewBox}
                            >
                                <use xlinkHref={linkOutIcon.url} />
                            </svg>
                        </a>
                    </header>
                    <section className="sski-c-link-modal__body modal-card-body container p-0">
                        <iframe title="flink" src={link.links} width="100%" height="100%" />
                    </section>
                </div>
            </div>
        </div>
    );
};

LinkViewModal.propTypes = {
    location: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    links: PropTypes.array,
};

LinkViewModal.defaultProps = {
    links: [],
};

const mapStateToProps = state => {
    return {
        links: state.application.links,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => dispatch({type: applicationActionTypes.CLOSE_LINK_VIEW_MODAL}),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LinkViewModal);
