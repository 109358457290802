import React, {useEffect, useState} from 'react';
import {useRive, Layout, Fit, Alignment, useStateMachineInput} from '@rive-app/react-canvas';
import PropTypes from 'prop-types';
import {phaseNamesMapper} from '../../../utils/ftemConstants';
import {phaseTypes, subPhaseTypes} from '@swiss-ski/sski-entity-types';
import InfoCard from '../InfoCard';

const InfoCards = ({hoveredPhase, selectedPhase, introductionContents, selectedSubphase, isMobile, sortDetails, setHoveredPhase}) => {
    return (
        ((hoveredPhase && selectedPhase && introductionContents) || selectedSubphase) && (
            <div
                className={`${
                    (hoveredPhase === phaseTypes.MASTERY && !selectedSubphase)
                        ? 'is-flex is-justify-content-center is-align-items-center is-flex-direction-row-reverse'
                        : ''}
                                    ${isMobile ? 'sski-c-dashboard-screen__mobile-legend-container' : ''}
                                    sski-c-dashboard-screen__legend-container--${selectedSubphase ? 'subphase' : hoveredPhase.toLowerCase()}`}
            >
                {(!selectedSubphase && !isMobile) && (
                    <div
                        className={`sski-c-dashboard-screen__legend-line--${hoveredPhase.toLowerCase()}`}
                    />
                )}
                {(selectedSubphase && hoveredPhase)
                    ? sortDetails.length > 0 ? null : null
                    : hoveredPhase ? (
                        <InfoCard
                            hoveredPhase={hoveredPhase}
                            selectedPhase={selectedPhase}
                            selectedPhaseTitle={introductionContents[Object.values(subPhaseTypes[hoveredPhase])[0]]?.text || ''}
                            isMobile={isMobile}
                            closeCard={setHoveredPhase}
                        />
                    ) : null}
            </div>
        )
    );
};

const FTEMPillarsRiveAnimation = props => {
    const {onHover, onClick, hoveredPhase, selectedPhase, introductionContents, selectedSubphase, isMobile, sortDetails, setHoveredPhase, topics} = props;
    const {RiveComponent, rive} = useRive({
        src: `${process.env.PUBLIC_URL}/assets/animation/ftem_navigation_remastered.riv`,
        stateMachines: 'FTEM Navigator',
        artboard: 'FTEM Navigator',
        // animations: 'FTEM Navigator',
        autoplay: true,
        layout: new Layout({
            fit: Fit.Contain,
            alignment: Alignment.BottomCenter,
        }),
    });
    const [isTClicked, setIsTClicked] = useState(false);
    // selector for animation control
    const selectInputE = useStateMachineInput(rive, 'FTEM Navigator', 'isSelected-t2');
    const selectT = useStateMachineInput(rive, 'FTEM Navigator', 'isClickedT');
    const isSelectedT1 = useStateMachineInput(rive, 'FTEM Navigator', 'isSelected-t1');
    const isSelectedT2 = useStateMachineInput(rive, 'FTEM Navigator', 'isSelected-t2');
    const isSelectedT3 = useStateMachineInput(rive, 'FTEM Navigator', 'isSelected-t3');
    const isSelectedT4 = useStateMachineInput(rive, 'FTEM Navigator', 'isSelected-t4');

    useEffect(() => {
        if (rive) {
            rive.on('statechange', event => {
                const allEvents = rive?.prevAnim;
                const currentStateChangeName = event.data.length === 1 ? event.data[0] : null;
                if (!currentStateChangeName) return;
                const splitStateChangeName = currentStateChangeName.split('-');
                const lastSplitStateChangeNameElement = splitStateChangeName[splitStateChangeName.length - 1];
                const formatM = splitStateChangeName[0] === 'm1' ? 'm' : splitStateChangeName[0];
                const isHoverLast = allEvents?.includes('hover');
                const prefixLast = allEvents?.split('-')[0];
                const isIdleNow = currentStateChangeName?.includes('idle');

                if (currentStateChangeName === 'T-clicked' && !isTClicked) {
                    setIsTClicked(true);
                } else {
                    setIsTClicked(false);
                }

                if (isHoverLast && isIdleNow && prefixLast !== splitStateChangeName[0]) {
                    return;
                }
                rive.prevAnim = event.data[0];

                if (lastSplitStateChangeNameElement === 'hover') {
                    onHover(phaseNamesMapper[splitStateChangeName[0].toUpperCase()]);
                    onClick('');
                }

                if (lastSplitStateChangeNameElement === 'idle') {
                    onHover('');
                    onClick('');
                }

                if (lastSplitStateChangeNameElement === 'clicked') {
                    onClick(formatM);
                }
            });
        }
    }, [rive]);

    const handleClick = isSubPhase => {
        if (rive) {
            if (isSubPhase === 't1') {
                isSelectedT1.fire();
            }
            if (isSubPhase === 't2') {
                isSelectedT2.fire();
            }
            if (isSubPhase === 't3') {
                isSelectedT3.fire();
            }
            if (isSubPhase === 't4') {
                isSelectedT4.fire();
            }
        }
    };

    useEffect(() => {
        if (!selectedSubphase && hoveredPhase && isSelectedT4) {
            console.log('are we in hree', hoveredPhase, rive?.contents);
        }
    }, [selectedSubphase]);

    return (
        <div className="is-flex is-justify-content-center">
            <div
                className="is-flex"
                style={{position: 'relative', width: '80vw', top: '40vh', aspectRatio: '2.6 / 1'}}
            >

                <InfoCards
                    hoveredPhase={hoveredPhase}
                    selectedPhase={selectedPhase}
                    introductionContents={introductionContents}
                    selectedSubphase={selectedSubphase}
                    isMobile={isMobile}
                    sortDetails={sortDetails}
                    setHoveredPhase={setHoveredPhase}
                />
                <RiveComponent />
                {isTClicked && (
                <div>
                    <div className="subphase-pillar t1-pillar" onClick={() => handleClick('t1')} />
                    <div className="subphase-pillar t2-pillar" onClick={() => handleClick('t2')} />
                    <div className="subphase-pillar t3-pillar" onClick={() => handleClick('t3')} />
                    <div className="subphase-pillar t4-pillar" onClick={() => handleClick('t4')} />
                </div>
                )}
                {/* {Object.keys(phaseTypes).map(phase => (phase === 'TALENT' && !selectedSubphase) && <div onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)} onClick={() => handleClick()} className={`sski-animation__pillar sski-animation__pillar--${phase.toLowerCase()}`} />)} */}
            </div>
        </div>
    );
};

FTEMPillarsRiveAnimation.propTypes = {
    onHover: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};


export default FTEMPillarsRiveAnimation;
