import {select} from 'redux-saga/effects';
import config from '../../config';

const provideStrapiTokenToServiceFlow = function* provideStrapiTokenToServiceFlow() {
    const {serviceManager} = yield select(state => state.application);

    // todo in future real token should be generated and passed here
    const ftemStrapiService = serviceManager.loadService('ftemStrapiService');
    ftemStrapiService.setAccessToken(config.FTEM_STRAPI_API_BEARER_TOKEN);
};

export default provideStrapiTokenToServiceFlow;
