/**
 * Command action types
 */
export const OPEN_LINK_VIEW_MODAL = '[application] OPEN_LINK_VIEW_MODAL';
export const CLOSE_LINK_VIEW_MODAL = '[application] CLOSE_LINK_VIEW_MODAL';

/**
 * Event action types
 */

/**
 * Store action types
 */
export const STORE_LINKS = '[application] STORE_LINKS';
