import {default as enUS} from './enUS.json';
import {default as deDE} from './deDE.json';
import {default as frFR} from './frFR.json';
import {default as itIT} from './itIT.json';
import {default as rmRM} from './rmRM.json';

export default {
    [deDE.locale]: deDE,
    [enUS.locale]: enUS,
    [frFR.locale]: frFR,
    [itIT.locale]: itIT,
    [rmRM.locale]: rmRM,
};
