import {immerable} from 'immer';
import Entity from '../entity/Entity';

/**
 * Link entity type definition
 *
 * @typedef {Object} Link
 * @property {?string} itemKey
 * @property {?string} title
 * @property {?string} link
 * @property {?string} type
 */

/**
 * Link entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const linkEntityDTOMapping = {
    id: 'itemKey',
    title: 'title',
    links: 'link',
    type: 'type',
};

/**
 * Link entity
 *
 * @class
 * @extends Entity
 * @type {Link}
 */
export default class Link extends Entity {
    [immerable] = true;

    static entityDTOMapping = linkEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
