/**
 * Command action types
 */
export const INITIATE_FETCH_TOPIC_CONTENTS_FLOW = '[dashboard] INITIATE_FETCH_TOPIC_CONTENTS_FLOW';

/**
 * Event action types
 */
export const FETCH_SPORTS_REQUEST = '[dashboard] FETCH_SPORTS_REQUEST';
export const FETCH_SPORTS_REQUEST_SENT = '[dashboard] FETCH_SPORTS_REQUEST_SENT';
export const FETCH_SPORTS_REQUEST_SUCCEEDED = '[dashboard] FETCH_SPORTS_REQUEST_SUCCEEDED';
export const FETCH_SPORTS_REQUEST_FAILED = '[dashboard] FETCH_SPORTS_REQUEST_FAILED';

export const FETCH_PHASES_REQUEST = '[dashboard] FETCH_PHASES_REQUEST';
export const FETCH_PHASES_REQUEST_SENT = '[dashboard] FETCH_PHASES_REQUEST_SENT';
export const FETCH_PHASES_REQUEST_SUCCEEDED = '[dashboard] FETCH_PHASES_REQUEST_SUCCEEDED';
export const FETCH_PHASES_REQUEST_FAILED = '[dashboard] FETCH_PHASES_REQUEST_FAILED';

export const FETCH_TOPICS_REQUEST = '[dashboard] FETCH_TOPICS_REQUEST';
export const FETCH_TOPICS_REQUEST_SENT = '[dashboard] FETCH_TOPICS_REQUEST_SENT';
export const FETCH_TOPICS_REQUEST_SUCCEEDED = '[dashboard] FETCH_TOPICS_REQUEST_SUCCEEDED';
export const FETCH_TOPICS_REQUEST_FAILED = '[dashboard] FETCH_TOPICS_REQUEST_FAILED';

export const FETCH_ELEMENTS_REQUEST = '[dashboard] FETCH_ELEMENTS_REQUEST';
export const FETCH_ELEMENTS_REQUEST_SENT = '[dashboard] FETCH_ELEMENTS_REQUEST_SENT';
export const FETCH_ELEMENTS_REQUEST_SUCCEEDED = '[dashboard] FETCH_ELEMENTS_REQUEST_SUCCEEDED';
export const FETCH_ELEMENTS_REQUEST_FAILED = '[dashboard] FETCH_ELEMENTS_REQUEST_FAILED';

export const FETCH_INTRODUCTION_CONTENTS_REQUEST = '[dashboard] FETCH_INTRODUCTION_CONTENTS_REQUEST';
export const FETCH_INTRODUCTION_CONTENTS_REQUEST_SENT = '[dashboard] FETCH_INTRODUCTION_CONTENTS_REQUEST_SENT';
export const FETCH_INTRODUCTION_CONTENTS_REQUEST_SUCCEEDED = '[dashboard] FETCH_INTRODUCTION_CONTENTS_REQUEST_SUCCEEDED';
export const FETCH_INTRODUCTION_CONTENTS_REQUEST_FAILED = '[dashboard] FETCH_INTRODUCTION_CONTENTS_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_SPORTS = '[dashboard] STORE_SPORTS';
export const STORE_PHASES = '[dashboard] STORE_PHASES';
export const STORE_TOPICS = '[dashboard] STORE_TOPICS';
export const STORE_ELEMENTS = '[dashboard] STORE_ELEMENTS';
export const STORE_INTRODUCTION_CONTENTS = '[dashboard] STORE_INTRODUCTION_CONTENTS';
