import {produce} from 'immer';
import Link from '@swiss-ski/sski-entity-types/src/topic/Link';
import * as applicationActionTypes from './applicationActionTypes';

/**
 * Initial application domain state
 *
 * @type {Object}
 */
const initialState = {
    serviceManager: null,
    links: [],
};

/**
 * Application reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const applicationReducer = produce((draft, action) => {
    switch (action.type) {
        case applicationActionTypes.STORE_LINKS: {
            const {links} = action.payload;
            draft.links = links.map(link => new Link().fromDTO(link));
            break;
        }
        default:
        // no-op
    }
}, initialState);

export default applicationReducer;
