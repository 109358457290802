import {produce, current} from 'immer';
import {Phase, Sport, Topic, Element} from '@swiss-ski/sski-entity-types';
import * as dashboardActionTypes from './dashboardActionTypes';

const initialState = {
    sports: [],
    phases: [],
    subPhaseNames: [],
    topics: [],
    introductionContents: {},
};

/**
 * Dashboard reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */

const dashboardReducer = produce((draft, action) => {
    switch (action.type) {
        case dashboardActionTypes.STORE_SPORTS: {
            const {sportDTOs} = action.payload;
            draft.sports = sportDTOs.map(sport => new Sport().fromDTO(sport));
            break;
        }
        case dashboardActionTypes.STORE_PHASES: {
            const {phaseDTOs} = action.payload;
            draft.phases = phaseDTOs.map(phase => new Phase().fromDTO(phase));
            draft.phases.forEach(phase => {
                if (!phase.subPhases || phase.subPhases.length === 0) return;
                phase.subPhases.forEach(subPhase => {
                    if (draft.subPhaseNames.includes(subPhase.name)) return;
                    draft.subPhaseNames.push(subPhase.name);
                });
            });
            break;
        }
        case dashboardActionTypes.STORE_TOPICS: {
            const {topicDTOs} = action.payload;
            draft.topics = topicDTOs.map(topic => new Topic().fromDTO(topic)).filter(topic => (
                topic.subTopics.length > 0
            ));
            break;
        }
        case dashboardActionTypes.STORE_ELEMENTS: {
            const {elementDTOs} = action.payload;
            // TODO set prev state in detailContent in order to prevent fetching data which is already fetched
            const detailContent = [];
            const currentState = current(draft).detailContent ?? [];
            elementDTOs.forEach(element => {
                const elementObj = new Element().fromDTO(element);
                const isPresent = obj => obj.id === elementObj.id;
                if (!currentState?.some(isPresent)) {
                    detailContent.push(elementObj);
                }
            });
            const mergeWithOld = [...currentState, ...detailContent];
            draft.detailContent = mergeWithOld;
            break;
        }
        case dashboardActionTypes.STORE_INTRODUCTION_CONTENTS: {
            const {elementDTOs} = action.payload;
            let introductionContents;

            elementDTOs.forEach(element => {
                // todo investigate why some elements do not have ftemPhase value
                //  and switch to element.ftemPhase.name for keys if possible
                const splitItemKey = element.itemKey.split('-');
                const ftemPhaseName = splitItemKey[splitItemKey.length - 1].toUpperCase();

                introductionContents = {
                    ...introductionContents,
                    [ftemPhaseName]: new Element().fromDTO(element),
                };
            });
            draft.introductionContents = introductionContents;
            break;
        }
        default:
            // no-op
    }
}, initialState);

export default dashboardReducer;
