/**
 * Command action types
 */
export const INITIATE_FETCH_CATEGORIES_CONTENT_FLOW = '[categories] INITIATE_FETCH_CATEGORIES_CONTENT_FLOW';

/**
 * Event action types
 */
export const FETCH_CATEGORIES_REQUEST = '[categories] FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_REQUEST_SENT = '[categories] FETCH_CATEGORIES_REQUEST_SENT';
export const FETCH_CATEGORIES_REQUEST_SUCCEEDED = '[categories] FETCH_CATEGORIES_REQUEST_SUCCEEDED';
export const FETCH_CATEGORIES_REQUEST_FAILED = '[categories] FETCH_CATEGORIES_REQUEST_FAILED';

export const FETCH_ELEMENTS_REQUEST = '[categories] FETCH_ELEMENTS_REQUEST';
export const FETCH_ELEMENTS_REQUEST_SENT = '[categories] FETCH_ELEMENTS_REQUEST_SENT';
export const FETCH_ELEMENTS_REQUEST_SUCCEEDED = '[categories] FETCH_ELEMENTS_REQUEST_SUCCEEDED';
export const FETCH_ELEMENTS_REQUEST_FAILED = '[categories] FETCH_ELEMENTS_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_CATEGORIES = '[categories] STORE_CATEGORIES';
export const STORE_ELEMENTS = '[categories] STORE_ELEMENTS';

