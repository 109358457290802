import {select, fork, take, put} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import {replace, resolveRoute} from '@computerrock/formation-router';
import * as dashboardActionTypes from '../dashboardActionTypes';
import routePaths from '../../routePaths';
import * as applicationActionTypes from '../../application/applicationActionTypes';

const loadSports = function* loadSports({payload}) {
    const {match, location} = payload;
    const {params, path} = match;
    const {sportId} = params;
    const {serviceManager} = yield select(state => state.application);
    const ftemStrapiService = serviceManager.loadService('ftemStrapiService');

    // sorting so 'cross-country-skiing' is first
    const filterParamsString = 'sort=createdAt';

    yield fork(
        fetchRequest,
        dashboardActionTypes.FETCH_SPORTS_REQUEST,
        ftemStrapiService.getSports, {
            filterParamsString,
        },
    );

    const responseAction = yield take([
        dashboardActionTypes.FETCH_SPORTS_REQUEST_SUCCEEDED,
        dashboardActionTypes.FETCH_SPORTS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {sportDTOs} = response;

        yield put({
            type: dashboardActionTypes.STORE_SPORTS,
            payload: {sportDTOs},
        });

        const links = sportDTOs.map(sport => sport.link).filter(link => link);
        yield put({
            type: applicationActionTypes.STORE_LINKS,
            payload: {links},
        });

        if (!sportId && path === routePaths.DASHBOARD) {
            const queryParams = new URLSearchParams(location?.search);
            yield put(replace(resolveRoute(
                routePaths.DASHBOARD,
                {sportId: sportDTOs[0].itemKey},
                {search: queryParams.toString()},
            )));
        }
    }
};

export default loadSports;
