import {produce} from 'immer';
import {Topic, phaseTypes, Element, subPhaseTypes} from '@swiss-ski/sski-entity-types';
import * as categoryActionTypes from './categoryActionTypes';

const initialState = {
    categoryOptions: [],
    selectedCategoriesTableContent: {},
    categoriesLanguage: 'de',
};

/**
 * Dashboard reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */

const categoryReducer = produce((draft, action) => {
    switch (action.type) {
        case categoryActionTypes.STORE_CATEGORIES: {
            const {topicDTOs, responseLanguage} = action.payload;
            draft.categoryOptions = topicDTOs.map(topic => new Topic().fromDTO(topic));
            draft.categoriesLanguage = responseLanguage;
            break;
        }
        case categoryActionTypes.STORE_ELEMENTS: {
            const {elementDTOs, categoryIds} = action.payload;
            const selectedCategoriesTableContent = new Map();
            const selectedCategoryIds = draft.categoryOptions.filter(category => (
                categoryIds.includes(category.id)
            )).map(category => category.id);

            const filterElementsByPhaseType = (elements, subTopicId, phaseType) => {
                const filteredElements = elements.filter(element => (
                    element.subTopic?.itemKey === subTopicId
                    && element.ftemPhase?.name?.includes(phaseTypes[phaseType][0])
                )).map(element => new Element().fromDTO(element));

                if (Object.keys(subPhaseTypes[phaseType]).length === filteredElements.length) {
                    return filteredElements.sort((a, b) => (
                        a.ftemPhase.name.localeCompare(b.ftemPhase.name)
                    ));
                }

                const filteredElementsWithEmptyContent = [];

                Object.keys(subPhaseTypes[phaseType]).forEach(key => {
                    const element = filteredElements.find(element => (
                        element.ftemPhase.name === subPhaseTypes[phaseType][key]
                    ));
                    if (!element) {
                        filteredElementsWithEmptyContent.push({
                            ftemPhase: {
                                name: subPhaseTypes[phaseType][key],
                            },
                            text: '',
                        });
                        return;
                    }
                    filteredElementsWithEmptyContent.push(element);
                });
                return filteredElementsWithEmptyContent.sort((a, b) => (
                    a.ftemPhase.name.localeCompare(b.ftemPhase.name)
                ));
            };

            selectedCategoryIds.forEach(categoryId => {
                const subCategories = {};
                const category = draft.categoryOptions.find(category => category.id === categoryId);
                category?.subTopics.sort((subTopicA, subTopicB) => (
                    subTopicA.sortKey - subTopicB.sortKey
                )).forEach(subTopic => {
                    subCategories[subTopic.id] = {
                        name: subTopic.name,
                        [phaseTypes.FOUNDATION]: filterElementsByPhaseType(elementDTOs, subTopic.id, phaseTypes.FOUNDATION), // eslint-disable-line max-len
                        [phaseTypes.TALENT]: filterElementsByPhaseType(elementDTOs, subTopic.id, phaseTypes.TALENT),
                        [phaseTypes.ELITE]: filterElementsByPhaseType(elementDTOs, subTopic.id, phaseTypes.ELITE),
                        [phaseTypes.MASTERY]: filterElementsByPhaseType(elementDTOs, subTopic.id, phaseTypes.MASTERY),
                    };
                });
                selectedCategoriesTableContent[categoryId] = {name: category?.name, subCategories};
            });
            draft.selectedCategoriesTableContent = selectedCategoriesTableContent;
            break;
        }
    }
}, initialState);

export default categoryReducer;
