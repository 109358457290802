import React from 'react';
import PropTypes from 'prop-types';
import {phaseTypes} from '@swiss-ski/sski-entity-types';
import './Card.scss';

const Card = props => {
    const {variant, children, isPhase, isSubphase, index} = props;

    return (
        <div
            className={`${isSubphase ? `sski-c-card_container--subphase` : 'sski-c-card_container--phase'}
                 p-0 sski-c-card_container sski-c-card_container--${variant.toLowerCase()} 
                 ${index === 0 ? 'first' : ''}
                 ${isPhase
                ? `sski-c-card_container--width-small sski-c-card_container--margin-${isSubphase ? 'subphase' : variant.toLowerCase()}`
                : ''
            }`}
        >
            <div className="p-4 sski-c-card_container__content">
                {children}
            </div>
        </div>
    );
};

Card.propTypes = {
    variant: PropTypes.oneOf([
        phaseTypes.FOUNDATION,
        phaseTypes.TALENT,
        phaseTypes.ELITE,
        phaseTypes.MASTERY,
    ]).isRequired,
    isPhase: PropTypes.bool,
    isSubphase: PropTypes.bool,
};

Card.defaultProps = {
    isPhase: false,
    isSubphase: false,
};

export default Card;
