import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {phaseTypes, subPhaseTypes} from '@swiss-ski/sski-entity-types';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useMediaQuery} from 'react-responsive';
import {ReactSVG} from 'react-svg';
import SidebarNavigation from './ui-elements/SidebarNavigation';
import './DashboardScreen.scss';
import FTEMPillarsRiveAnimation from './ui-elements/animation/FTEMPillarsRiveAnimation';
import routePaths from '../routePaths';
import InfoCard from './ui-elements/InfoCard';
import {SUBTOPICS_ID} from '../utils/ftemConstants';
import * as dashboardActionTypes from './dashboardActionTypes';
import * as applicationActionTypes from '../application/applicationActionTypes';
import DataTableNavigationBar from '../categories/ui-elements/DataTableNavigationBar';
import MobileAnimation from './ui-elements/animation/MobileAnimation';
import PhaseLoader from './ui-elements/PhaseLoader';
import {MobileDevice} from '../index';


const DashboardScreen = props => {
    const {subPhaseNames, phases, topics, detailContent, introductionContents, sports, history, match} = props;
    const {initiateLinkViewModalFlow, initiateFetchTopicContentsFlow} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('dashboard_screen');

    // todo for now we have only one screen where we use ftem animation so we can control state from this component
    //  depending on whether we'll have the same logic for other screens in the future
    //  we should consider moving logic to the context provider
    const [hoveredPhase, setHoveredPhase] = useState('');
    const [clickedPhase, setClickedPhase] = useState('');
    const [selectedPhase, setSelectedPhase] = useState(null);
    const [selectedSubphase, setSelectedSubphase] = useState(null);
    const {isMobile} = useContext(MobileDevice);
    const scrollRef = useRef(null);
    const sportId = match?.params?.sportId;
    const selectedSport = sports.find(sport => sport.id === sportId);

    const filterDetails = detailContent.filter(detail => {
        const formatName = detail.id.split('-');
        const getName = formatName[2];
        return getName === clickedPhase;
    }) ?? [];
    const sortDetails = SUBTOPICS_ID.filter(topic => topic !== 'introduction-home').map(i => filterDetails.find(j => j.id.includes(i))).filter(x => x);

    const scrollToTop = () => {
        scrollRef.current.scroll({
            top: '-20px',
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (!hoveredPhase || phases.length === 0) return;
        const phase = phases.find(phase => phase.id === hoveredPhase.toLowerCase());
        if (!phase) return;
        const findParent = () => {
            let parentIndex;
            phases?.map((phase, i) => {
                return phase?.subPhases?.find(topic => {
                    if (topic.id === clickedPhase) {
                        return parentIndex = i;
                    }
                });
            });
            if (phases[parentIndex]) {
                return phases[parentIndex];
            }
        };
        const getParent = findParent();
        const subPhase = getParent?.subPhases?.find(selected => selected.id === clickedPhase);
        setSelectedPhase(getParent || phase);
        if (clickedPhase.length === 1 && clickedPhase !== 'm') {
            const findHoverPillar = phases.find(phase => phase.id.slice(0, 1) === clickedPhase.toLowerCase()).id.toUpperCase();
            setSelectedSubphase(null);
            return setHoveredPhase(findHoverPillar);
        }
        setSelectedSubphase(subPhase ?? null);
        if (getParent) {
            setHoveredPhase(getParent.id.toUpperCase());
        }
        const isPhaseFetched = detailContent.find(detail => {
            const formatName = detail.id.split('-');
            const getName = formatName[2];
            return getName === clickedPhase;
        });

        if (subPhaseNames.find(subphase => subphase === clickedPhase.toUpperCase()) && !isPhaseFetched) {
            // todo get sport ID from route
            initiateFetchTopicContentsFlow({
                selectedSubPhaseId: clickedPhase,
                selectedSportId: 'cross-country-skiing',
            });
        }
    }, [hoveredPhase, phases, clickedPhase]);

    useEffect(() => {
        if (sortDetails.length > 0 && isMobile) {
            scrollToTop();
        }
    }, [sortDetails]);

    return (
        <div className="grid">
            <div className="columns is-gapless">
                <div className="column is-narrow sski-c-sidebar">
                    <SidebarNavigation
                        isMobile={isMobile}
                        isSubPhase={selectedSubphase && hoveredPhase && sortDetails.length > 0}
                        setClickedPhase={setClickedPhase}
                    />
                </div>
                <div className="column sski-c-dashboard-screen">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/ss-ftm-bg.svg`}
                        alt="sski-bg"
                        className="sski-c-dashboard-screen__background"
                    />
                    {(selectedSport && !selectedSubphase) && (
                        <div
                            className="sski-c-dashboard-screen__top-bar mt-5 is-position-absolute is-flex is-justify-content-center"
                        >
                            <button
                                type="button"
                                className="sski-c-dashboard-screen__button
                                            sski-c-dashboard-screen__button--small
                                            button is-size-4 is-size-6-mobile"
                                onClick={!isMobile ? () => initiateLinkViewModalFlow({
                                    linkType: 'default',
                                    linkId: selectedSport.link?.id,
                                }) : () => window.open(selectedSport.link?.links, '_blank')}
                            >
                                {`${translateScreen('button_label.mission')} ${selectedSport.title}`}
                                <img
                                    className="sski-c-dashboard-screen__button_arrow_img ml-3"
                                    alt="arrow-right"
                                    src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`}
                                />
                            </button>
                        </div>
                    )}
                    {!isMobile ? (
                        <FTEMPillarsRiveAnimation
                            subPhaseNames={subPhaseNames}
                            onHover={setHoveredPhase}
                            onClick={setClickedPhase}
                            hoveredPhase={hoveredPhase}
                            selectedPhase={selectedPhase}
                            introductionContents={introductionContents}
                            selectedSubphase={selectedSubphase}
                            isMobile={isMobile}
                            sortDetails={sortDetails}
                            setHoveredPhase={setHoveredPhase}
                        />
                    ) : (
                        <>
                            <MobileAnimation onHover={setHoveredPhase} phaseSelected={hoveredPhase.slice(0, 1)} />
                            {hoveredPhase && (
                                <DataTableNavigationBar
                                    subPhases={Object.values(subPhaseTypes).map(subPhaseType => Object.values(subPhaseType)).flat().filter(phase => phase.includes(hoveredPhase.slice(0, 1)))}
                                    phasePrefix={hoveredPhase}
                                    clickedPhase={clickedPhase}
                                    onSelectedSubphase={setClickedPhase}
                                />
                            )}
                        </>
                    )}
                    {((hoveredPhase && selectedPhase && introductionContents) || selectedSubphase) && (
                        <div
                            ref={scrollRef}
                            className={`${
                                (hoveredPhase === phaseTypes.MASTERY && !selectedSubphase)
                                    ? 'is-flex is-justify-content-center is-align-items-center is-flex-direction-row-reverse'
                                    : ''} 
                                    ${isMobile ? 'sski-c-dashboard-screen__mobile-legend-container' : ''} 
                                    sski-c-dashboard-screen__legend-container--${selectedSubphase ? 'subphase' : hoveredPhase.toLowerCase()}`}
                        >
                            {(selectedSubphase && hoveredPhase)
                                ? sortDetails.length > 0 ? sortDetails.map((detail, index) => (
                                    <>
                                        {index === 0 && (
                                            <div className="sski-c-dashboard-screen__mobile-subphase-handler">
                                                <ReactSVG
                                                    alt="close icon"
                                                    src={`${process.env.PUBLIC_URL}/assets/icons/exit-circle.svg`}
                                                    onClick={() => setClickedPhase('')}
                                                />
                                            </div>
                                        )}
                                        <InfoCard
                                            key={detail?.itemKey}
                                            hoveredPhase={hoveredPhase}
                                            selectedPhase={selectedPhase}
                                            selectedSubphase={detail}
                                            topics={topics}
                                            index={index}
                                        />
                                    </>
                                )) : (
                                    <div className="columns container is-desktop">
                                        <PhaseLoader />
                                        <PhaseLoader />
                                        <PhaseLoader />
                                    </div>
                                )
                                : (hoveredPhase && isMobile) ? (
                                    <InfoCard
                                        hoveredPhase={hoveredPhase}
                                        selectedPhase={selectedPhase}
                                        selectedPhaseTitle={introductionContents[Object.values(subPhaseTypes[hoveredPhase])[0]]?.text || ''}
                                        isMobile={isMobile}
                                        closeCard={setHoveredPhase}
                                    />
                                ) : null}
                        </div>
                    )}
                    {((isMobile && !hoveredPhase) || !isMobile) && (
                        <div className="sski-c-dashboard-screen__button_container">
                            <button
                                type="button"
                                className={`${isMobile ? '' : 'p-5 mr-6 mb-6'} button is-pulled-right sski-c-dashboard-screen__button`}
                                onClick={() => {
                                    const queryParams = new URLSearchParams(history?.location.search);
                                    history.push(resolveRoute(routePaths.CATEGORIES, {}, {search: queryParams.toString()}));
                                }}
                            >
                                <ReactSVG alt="diagram" src={`${process.env.PUBLIC_URL}/assets/icons/diagram.svg`} />
                                <p className={`ml-1 has-text-weight-medium ${isMobile ? 'is-size-5' : 'is-size-4'} `}>
                                    {translateScreen('button_label.interactive_poster')}
                                </p>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

DashboardScreen.propTypes = {
    subPhaseNames: PropTypes.array,
    phases: PropTypes.array,
    introductionContents: PropTypes.object,
    topics: PropTypes.array,
    sports: PropTypes.array,
    detailContent: PropTypes.array,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    initiateLinkViewModalFlow: PropTypes.func.isRequired,
    initiateFetchTopicContentsFlow: PropTypes.func.isRequired,
};

DashboardScreen.defaultProps = {
    subPhaseNames: [],
    phases: [],
    introductionContents: null,
    topics: [],
    sports: [],
    detailContent: [],
};

const mapStateToProps = state => {
    return {
        subPhaseNames: state.dashboard.subPhaseNames,
        phases: state.dashboard.phases,
        introductionContents: state.dashboard.introductionContents,
        topics: state.dashboard.topics,
        sports: state.dashboard.sports,
        detailContent: state.dashboard.detailContent,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initiateLinkViewModalFlow: payload => dispatch({
            type: applicationActionTypes.OPEN_LINK_VIEW_MODAL,
            payload,
        }),
        initiateFetchTopicContentsFlow: payload => dispatch({
            type: dashboardActionTypes.INITIATE_FETCH_TOPIC_CONTENTS_FLOW,
            payload,
        }),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardScreen));
