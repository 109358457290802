import contentType from 'content-type';
import ServerResponseError from './ServerResponseError';

/**
 * Parses response stream into usable format based on content-type
 *
 * @param response
 * @return {*}
 */
const parseResponseStream = response => {
    if (!response.headers.get('content-type')) {
        return response.status !== 201 && response.status !== 204
            ? Promise.reject(new Error('[service] Content-type does not exist.'))
            : Promise.resolve(`[service] Status: '${response.status}'`);
    }

    const responseContentType = contentType.parse(response.headers.get('content-type'));
    const {type} = responseContentType;

    if (['application/json'].indexOf(type) > -1) {
        return response.json();
    }

    if (['text/html', 'application/xml'].indexOf(type) > -1) {
        return response.text();
    }

    if (['application/octet-stream'].indexOf(type) > -1) {
        return Promise.resolve('');
    }

    if (['application/pdf',
        'image/jpeg',
        'image/png',
        'image/jpg',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-outlook'].indexOf(type) > -1) {
        return response.blob();
    }

    return Promise.reject(new Error(`[service] Content-type not parsed: '${type}'.`));
};

/**
 * Helper function for handling fetch response errors
 *
 * @param response
 * @return {*}
 */
const handleResponse = response => {
    const responseBody = parseResponseStream(response);

    if (response.ok) {
        return responseBody;
    }

    // lockServiceCase returns lockData even if request fails (400)
    // TBD: can we handle this in a better way?
    return responseBody.then(errorMessage => {
        throw new ServerResponseError(
            typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage),
            response,
        );
    });
};

export default handleResponse;
