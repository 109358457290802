import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {renderRoutes} from '@computerrock/formation-router';
import '@computerrock/formation-ui/index.module.scss';
import '../main.scss';
import './ApplicationScreen.scss';
import TranslationDropDown from './ui-elements/TranslationDropDown';

const ApplicationScreen = ({route}) => {
    return (
        <Fragment>
            <div className="is-flex is-justify-content-flex-end is-align-items-center sski-c-application-screen__translation_container">
                <TranslationDropDown />
            </div>
            {renderRoutes(route.routes)}
        </Fragment>
    );
};

ApplicationScreen.propTypes = {
    route: PropTypes.object.isRequired,
    // someStateValue: PropTypes.object,
};

ApplicationScreen.defaultProps = {
    // no-op
    // someStateValue: null,
};

const mapStateToProps = state => ({
    // no-op
    // someStateValue: state.domain.someStateValue
});

const mapDispatchToProps = dispatch => ({
    // no-op
    // callAction: payload => dispatch({
    //     type: someActionTypes.CALL_ACTION,
    //     payload,
    // }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ApplicationScreen);
