import {select} from 'redux-saga/effects';

const provideLocaleCodeToServiceFlow = function* provideLocaleCodeToServiceFlow({payload}) {
    const {location} = payload;
    const {query} = location;
    const {serviceManager} = yield select(state => state.application);
    const ftemStrapiService = serviceManager.loadService('ftemStrapiService');

    if (!query?.locale) return;

    try {
        ftemStrapiService.setLocaleCode(query.locale);
    } catch (e) {
        // no-op
    }
};

export default provideLocaleCodeToServiceFlow;
