import React from 'react';

const PhaseLoader = ({full}) => {
    return (
        <div className={`column ${full ? 'is-full' : 'is-4'} p-1`}>
            <div className="sski-card--skeleton_container px-2 py-3">
                <div className="skeleton-lines">
                    <div className="media-right">
                        <span
                            className="delete is-skeleton"
                        />
                    </div>
                    <div />
                    <div />
                    <div />
                    <div className="is-hidden-tablet" />
                    <div className="is-hidden-tablet" />
                    <div className="is-hidden-tablet" />
                    <div className="is-hidden-tablet" />
                    <div className="is-hidden-tablet" />
                </div>
            </div>
        </div>
    );
};

export default PhaseLoader;
