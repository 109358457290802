import modalIds from './modalIds';
import LinkViewModal from './application/modals/LinkViewModal';

/*
* Modal configuration
*/
export default [
    {
        id: modalIds.LINK_VIEW_MODAL,
        component: LinkViewModal,
    },
];
