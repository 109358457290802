import {fork} from 'redux-saga/effects';
// import authOIDC from './auth/authOIDC';
// import authOAuth2 from './auth/authOAuth2';
import applicationWatcher from './application/applicationWatcher';
import dashboardWatcher from './dashboard/dashboardWatcher';
import categoryWatcher from './categories/categoryWatcher';

// application sagas
export default [
    fork(applicationWatcher),
    fork(dashboardWatcher),
    fork(categoryWatcher),
    // fork(authOIDC.watcher),
    // fork(authOAuth2.watcher),
];
