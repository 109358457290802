import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import './TopNavigationBar.scss';
import routePaths from '../../routePaths';
import useOutsideClick from '../../utils/useOutsideClick';
import resolvePreviousRoute from '../../utils/resolvePreviousRoute';
import {CATEGORY_ID, FIXED_CATEGORY} from '../../utils/ftemConstants';
import {MobileDevice} from '../../index';

const TopNavigationBar = props => {
    const {history, categoryOptions, setIsLoading} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('categories_screen');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const dropDownRef = useRef(null);
    const {isMobile} = useContext(MobileDevice);
    const availableCategories = categoryOptions?.filter(category => (
        !!category.topicGroup && category.topicGroup !== 'none'
        && snakeCase(category.id) !== FIXED_CATEGORY
    ));
    const groupedCategories = availableCategories.reduce((acc, category) => {
        acc[category.topicGroup] = [...(acc[category.topicGroup] ? acc[category.topicGroup] : []), category];
        return acc;
    }, {});

    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const {location} = history ?? {};

    const handleClickOutside = () => {
        const queryParams = new URLSearchParams(location.search);
        const categoryIds = queryParams.getAll(CATEGORY_ID);
        setSelectedCategoryIds(categoryIds);
        setIsMenuOpen(false);
    };

    const handleClickBack = () => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete(CATEGORY_ID);
        history.push(resolvePreviousRoute(location, queryParams.toString()));
    };

    const handleSubmit = () => {
        const queryParams = new URLSearchParams(location.search);
        const newQueryParams = new URLSearchParams();
        selectedCategoryIds.forEach(categoryId => {
            newQueryParams.append(CATEGORY_ID, categoryId);
        });
        if (queryParams.get('locale')) newQueryParams.append('locale', queryParams.get('locale'));
        history.replace(resolveRoute(routePaths.CATEGORIES, {}, {search: newQueryParams.toString()}));
        setIsMenuOpen(false);
        setIsLoading(true);
    };

    const resetAll = () => {
        setSelectedCategoryIds([FIXED_CATEGORY]);
    };

    useEffect(() => {
        if (!location.search) return;
        const queryParams = new URLSearchParams(location.search);
        const categoryIds = queryParams.getAll(CATEGORY_ID);
        setSelectedCategoryIds(categoryIds);
    }, [location.search]);

    useOutsideClick(dropDownRef, handleClickOutside);

    return (
        <div className={`is-position-absolute ${isMenuOpen ? 'sski-c-top-navigation-bar__navigation-line' : ''}`}>
            <button
                type="button"
                className="sski-c-top-navigation-bar__back_button button is-white is-rounded is-responsive is-size-6 has-text-weight-normal"
                onClick={handleClickBack}
            >
                <img
                    alt="arrow-left"
                    className={`${!isMobile ? 'mr-2' : ''}`}
                    src={`${process.env.PUBLIC_URL}/assets/icons/arrow-left.svg`}
                />
                {!isMobile ? translateScreen('button_label.back') : null}
            </button>
            <div
                className={`sski-c-top-navigation-bar is-flex is-justify-content-center ${isMenuOpen
                    ? 'sski-c-top-navigation-bar__background-overlay'
                    : ''
                } ${isMobile ? 'px-2 pb-2' : ''}`}
            >
                <div ref={dropDownRef} className={`mt-2 dropdown ${isMenuOpen ? 'is-active' : ''}`}>
                    <div className="dropdown-trigger">
                        <button
                            type="button"
                            className={`sski-c-top-navigation-bar__dropdown_button button is-rounded ${isMobile ? 'is-size-6 px-2 py-1' : 'is-size-4'} has-text-weight-medium`}
                            aria-haspopup="true"
                            aria-controls="dropdown-menu"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                        >
                            <span className="mr-2">
                                {translateScreen('button_label.topics', {
                                    chosenTopics: availableCategories.length > 0
                                        ? selectedCategoryIds.filter(id => (
                                            id !== FIXED_CATEGORY
                                        )).length
                                        : 0,
                                    totalTopics: availableCategories.length,
                                })}
                            </span>
                            <img
                                alt="drop-down-arrow"
                                src={`${process.env.PUBLIC_URL}/assets/icons/arrow-down-bold.svg`}
                            />
                        </button>
                    </div>
                    <div
                        className="sski-c-top-navigation-bar__dropdown-menu dropdown-menu"
                        id="dropdown-menu"
                        role="menu"
                    >
                        <div className="sski-c-top-navigation-bar__dropdown-content-frame">
                            <div className="sski-c-top-navigation-bar__dropdown-content dropdown-content p-4">
                                <div className="sski-c-top-navigation-bar__button-container is-flex is-justify-content-end">
                                    <button className="pt-4 p-1" onClick={resetAll}>{translateScreen('button_label.reset_all')}</button>
                                </div>
                                {groupedCategories && Object.keys(groupedCategories).map((key, index) => (
                                    <div
                                        key={key}
                                        className={`p-2 mt-6 ${index === Object.keys(groupedCategories).length - 1
                                            ? 'sski-c-top-navigation-bar-bar__category--margin-bottom'
                                            : 'mb-6'}`}
                                    >
                                        <p className="sski-c-top-navigation-bar__category-title">
                                            {translateScreen(`category_type.${key.toLowerCase()}`)}
                                        </p>
                                        {groupedCategories[key]
                                            && Object.values(groupedCategories[key]).map(category => (
                                                <div
                                                    key={category.id}
                                                    className="sski-c-top-navigation-bar__dropdown-item is-flex is-justify-content-space-between is-align-items-center"
                                                    onClick={() => setSelectedCategoryIds(prevState => {
                                                        return prevState.includes(category.id)
                                                            ? (prevState.filter(id => category.id !== id))
                                                            : [...prevState, category.id];
                                                    })}
                                                >
                                                    <p>
                                                        {category.name}
                                                    </p>
                                                    {selectedCategoryIds.includes(category.id) && (
                                                    <img
                                                        alt="checkmark"
                                                        src={`${process.env.PUBLIC_URL}/assets/icons/checkmark.svg`}
                                                    />
                                                    )}
                                                </div>
                                            ))}
                                    </div>
                                ))}
                                <div className="sski-c-top-navigation-bar__dropdown-content-sticky-bottom p-5">
                                    <button
                                        type="button"
                                        className={`sski-c-top-navigation-bar__dropdown-content-button button is-rounded ${isMobile ? 'is-size-6' : 'is-size-4'}`}
                                        onClick={handleSubmit}
                                    >
                                        {translateScreen('button_label.submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

TopNavigationBar.propTypes = {
    history: PropTypes.object.isRequired,
    // location: PropTypes.object.isRequired, todo investigate why location object isn't updated with search params
    categoryOptions: PropTypes.array,
    setIsLoading: PropTypes.func,
};

TopNavigationBar.defaultProps = {
    categoryOptions: [],
};

const mapStateToProps = state => {
    return {
        categoryOptions: state.categories.categoryOptions,
    };
};

export default withRouter(connect(mapStateToProps)(TopNavigationBar));
