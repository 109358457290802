import {all, fork} from 'redux-saga/effects';
import provideStrapiTokenToServiceFlow from './sagas/provideStrapiTokenToServiceFlow';
import linkViewModalFlow from './sagas/linkViewModalFlow';

const applicationWatcher = function* applicationWatcher() {
    yield all([
        fork(provideStrapiTokenToServiceFlow),
        fork(linkViewModalFlow),
    ]);
};

export default applicationWatcher;
