import {select, fork, take, put} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as dashboardActionTypes from '../dashboardActionTypes';
import {SUBTOPICS_ID} from '../../utils/ftemConstants';

const fetchTopicContents = function* fetchTopicContents() {
    const {serviceManager} = yield select(state => state.application);
    const ftemStrapiService = serviceManager.loadService('ftemStrapiService');

    while (true) {
        const {payload} = yield take(dashboardActionTypes.INITIATE_FETCH_TOPIC_CONTENTS_FLOW);
        const {selectedSubPhaseId, selectedSportId} = payload;

        if (!selectedSubPhaseId || !selectedSportId) continue;

        const filterParamsString = SUBTOPICS_ID.filter(subTopicId => subTopicId !== 'introduction-home')
            .map((key, i) => {
                return `filters[$or][${i}][itemKey][$eq]=${key}-${selectedSubPhaseId}`;
            }).join('&');

        yield fork(
            fetchRequest,
            dashboardActionTypes.FETCH_ELEMENTS_REQUEST,
            ftemStrapiService.getElements,
            {filterParamsString},
        );

        const elementsResponseAction = yield take([
            dashboardActionTypes.FETCH_ELEMENTS_REQUEST_SUCCEEDED,
            dashboardActionTypes.FETCH_ELEMENTS_REQUEST_FAILED,
        ]);
        if (!elementsResponseAction.error) {
            const {response} = elementsResponseAction.payload;
            const {elementDTOs} = response;

            yield put({
                type: dashboardActionTypes.STORE_ELEMENTS,
                payload: {
                    elementDTOs,
                },
            });
        }
    }
};

export default fetchTopicContents;
