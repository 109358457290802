import handleResponse from './handleResponse';
import resourcePaths from './resourcePaths';
import resolveResourcePath from './resolveResourcePath';

/**
 * Strapi client
 *
 * @constructor
 */
export default class StrapiClient {
    /**
     * @param {Object} serviceParameters
     * @returns {Object}
     */
    constructor(serviceParameters = {}) {
        this.serviceParameters = {
            ...serviceParameters,
            SERVICE_URL: typeof serviceParameters.SERVICE_URL === 'string'
                ? serviceParameters.SERVICE_URL.replace(/\/?$/, '/')
                : '',
        };

        return {
            setAccessToken: this.setAccessToken,
            getLocaleCode: this.getLocaleCode,
            setLocaleCode: this.setLocaleCode,
            setAssociation: this.setAssociation,
            getPhases: this.getPhases,
            getElements: this.getElements,
            getSports: this.getSports,
            getUsers: this.getUsers,
            getUser: this.getUser,
            getUserTypes: this.getUserTypes,
            getTopics: this.getTopics,
        };
    }

    accessToken = null;
    localeCode = 'de';
    association = 'swissski'

    setAccessToken = accessToken => {
        this.accessToken = accessToken;
    };

    getLocaleCode = () => {
        return this.localeCode;
    };

    setLocaleCode = localeCode => {
        this.localeCode = localeCode;
    };

    setAssociation = association => {
        this.association = association;
    };

    getPhases = () => {
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourcePaths['PHASES'])
            + '?populate=*'
            + (this.localeCode ? `&locale=${this.localeCode}` : '');

        return fetch(resourceEndpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(response => ({phaseDTOs: response?.data, responseLanguage: this.localeCode}));
    };

    getElements = ({filterParamsString}) => {
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourcePaths['ELEMENTS'])
            + '?populate=*'
            + (this.localeCode ? `&locale=${this.localeCode}` : '')
            + (filterParamsString ? `&${filterParamsString}` : '');

        return fetch(resourceEndpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(response => ({elementDTOs: response?.data, responseLanguage: this.localeCode}));
    };

    getSports = ({filterParamsString}) => {
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourcePaths['SPORTS'])
            + `?filters[association][itemKey][$eq]=${this.association}`
            + (this.localeCode ? `&locale=${this.localeCode}` : '')
            + (filterParamsString ? `&${filterParamsString}` : '')
            + '&populate=*';

        return fetch(resourceEndpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(response => ({sportDTOs: response?.data, responseLanguage: this.localeCode}));
    };

    getUsers = () => {
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourcePaths['USERS']);

        return fetch(resourceEndpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(response => ({userDTOs: response?.data, responseLanguage: this.localeCode}));
    }

    getUser = ({userId}) => {
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourcePaths['USERS'], {userId});

        return fetch(resourceEndpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(response => ({userDTO: response?.data, responseLanguage: this.localeCode}));
    };

    getUserTypes = () => {
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourcePaths['USER_TYPES'])
            + (this.localeCode ? `?locale=${this.localeCode}` : '');

        return fetch(resourceEndpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(response => ({userTypeDTOs: response?.data, responseLanguage: this.localeCode}));
    }

    getTopics = queryParams => {
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourcePaths['TOPICS'])
            + '?populate=*'
            + (this.localeCode ? `&locale=${this.localeCode}` : '')
            + (queryParams ? `&${queryParams.filterParamsString}` : '');

        return fetch(resourceEndpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(response => ({topicDTOs: response?.data, responseLanguage: this.localeCode}));
    }
}
