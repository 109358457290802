import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useMediaQuery} from 'react-responsive';
import './TranslationDropDown.scss';
import useOutsideClick from '../../utils/useOutsideClick';


const languageOptions = {
    DE: 'DE',
    FR: 'FR',
    IT: 'IT',
    RM: 'RM',
};

const languageOptionsAlpha3Mapper = {
    DE: 'DEU',
    FR: 'FRA',
    IT: 'ITA',
    RM: 'RUM',
};
const TranslationDropDown = ({location, history}) => {
    const {translate, activeLocale, setActiveLocale} = useTranslate();
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(useMediaQuery({query: '(max-width: 768px)'}));
    const dropDownRef = useRef(null);

    const handleLanguageChange = locale => {
        const queryParams = new URLSearchParams(location.search);
        if (!queryParams.get('locale')) queryParams.append('locale', locale.toLowerCase());
        queryParams.set('locale', locale.toLowerCase());
        setActiveLocale(`${languageOptions[locale].toLowerCase()}-${languageOptions[locale] === languageOptions.EN ? 'US' : languageOptions[locale]}`);
        setIsOpen(false);

        if (!location?.pathname) return;

        history.replace(resolveRoute(location?.pathname, {}, {search: queryParams.toString()}));
    };

    useEffect(() => {
        if (!location?.search) return;
        const queryParams = new URLSearchParams(location.search);
        const locale = queryParams.get('locale');
        if (!locale) return;
        setActiveLocale(`${locale}-${locale.toUpperCase() === languageOptions.EN ? 'US' : locale.toUpperCase()}`);
    }, [location, setActiveLocale]);

    useOutsideClick(dropDownRef, () => setIsOpen(false));

    return (
        <div
            ref={dropDownRef}
            className={`sski-c-translation-dropdown__dropdown dropdown 
            ${isOpen ? 'is-active' : ''} ${isMobile ? 'mt-5' : 'pr-5'}  is-right`}
        >
            <div className="dropdown-trigger">
                <button
                    type="button"
                    className={`sski-c-translation-dropdown__button button is-rounded is-responsive 
                    is-size-6 ${isMobile ? 'px-2 mr-3' : ''}`}
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <img
                        className="sski-c-translation-dropdown__globe_icon"
                        alt="language"
                        src={`${process.env.PUBLIC_URL}/assets/icons/globe.svg`}
                    />
                    {!isMobile && (
                    <span className="ml-1 mr-2 has-text-weight-normal">
                        {languageOptionsAlpha3Mapper[activeLocale.split('-')[0]?.toUpperCase()]}
                    </span>
                    )}
                    <img
                        className="sski-c-translation-dropdown__arrow"
                        alt="drop-down-arrow"
                        src={`${process.env.PUBLIC_URL}/assets/icons/arrow-down.svg`}
                    />
                </button>
            </div>
            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                    {Object.keys(languageOptionsAlpha3Mapper).map(key => (
                        <div
                            className={`sski-c-translation-dropdown__dropdown-menu-item dropdown-item ${key === activeLocale.split('-')[0].toUpperCase() ? 'sski-c-translation-dropdown__dropdown-menu-item--active' : ''}`}
                            key={key}
                            onClick={() => handleLanguageChange(key)}
                        >
                            {translate(`dashboard_screen.translation_language.${languageOptions[key].toLowerCase()}`)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

TranslationDropDown.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(TranslationDropDown);
