import {select, fork, take, put} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import {replace, resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import {Topic} from '@swiss-ski/sski-entity-types';
import routePaths from '../../routePaths';
import * as categoriesActionTypes from '../categoryActionTypes';
import config from '../../config';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import {CATEGORY_ID} from '../../utils/ftemConstants';

const loadCategories = function* loadCategories({payload}) {
    const {location} = payload;
    const {query, state} = location ?? {};
    const {serviceManager} = yield select(state => state.application);
    const {categoryOptions, categoriesLanguage} = yield select(state => state.categories);
    const ftemStrapiService = serviceManager.loadService('ftemStrapiService');
    const filterParamsString = 'filters[level][$eq]=detail&sort=sortKey';
    const queryParams = new URLSearchParams(location?.search);

    if (!!query?.modal && state?.isModalOpen) return;

    let topicDTOs;

    if (queryParams.getAll(CATEGORY_ID).length === 0) {
        yield put(replace(resolveRoute(routePaths.CATEGORIES, {}, {search: prepareSearchQueryParams({
            id: config.DEFAULT_CATEGORIES,
            ...(queryParams.get('locale') ? {locale: queryParams.get('locale')} : {}),
        })})));
    }

    if (categoryOptions.length === 0
        || (queryParams.get('locale') && categoriesLanguage !== queryParams.get('locale'))) {
        yield fork(
            fetchRequest,
            categoriesActionTypes.FETCH_CATEGORIES_REQUEST,
            ftemStrapiService.getTopics, {
                filterParamsString,
            },
        );

        const responseAction = yield take([
            categoriesActionTypes.FETCH_CATEGORIES_REQUEST_SUCCEEDED,
            categoriesActionTypes.FETCH_CATEGORIES_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            topicDTOs = response.topicDTOs;
            const {responseLanguage} = response;

            yield put({
                type: categoriesActionTypes.STORE_CATEGORIES,
                payload: {
                    topicDTOs,
                    responseLanguage,
                },
            });
        }
    }

    if ((topicDTOs && topicDTOs.length > 0) || (categoryOptions && categoryOptions.length > 0)) {
        const queryParams = new URLSearchParams(location.search);
        const categoryIds = queryParams.getAll(CATEGORY_ID);


        if (!categoryIds || categoryIds.length === 0) return;

        const subTopics = categoryIds.map(categoryId => (
            !topicDTOs
                ? categoryOptions.find(topic => (
                    topic.id === categoryId
                ))?.subTopics
                : new Topic().fromDTO(topicDTOs.find(topic => (
                    topic.itemKey === categoryId
                )))?.subTopics
        )).filter(subTopic => !!subTopic)?.flat();
        const subTopicFilterParams = subTopics.map((subTopic, index) => (
            `filters[subTopic][id]=${subTopic.numID}`
        )).join('&');
        const elementFilterParamsString = `filters[level][$eq]=detail&sort=subTopic.sortKey&pagination[pageSize]=10000&${subTopicFilterParams}`;
        yield fork(
            fetchRequest,
            categoriesActionTypes.FETCH_ELEMENTS_REQUEST,
            ftemStrapiService.getElements,
            {filterParamsString: elementFilterParamsString},
        );

        const elementsResponseAction = yield take([
            categoriesActionTypes.FETCH_ELEMENTS_REQUEST_SUCCEEDED,
            categoriesActionTypes.FETCH_ELEMENTS_REQUEST_FAILED,
        ]);


        if (!elementsResponseAction.error) {
            const {response} = elementsResponseAction.payload;
            const {elementDTOs} = response;

            yield put({
                type: categoriesActionTypes.STORE_ELEMENTS,
                payload: {
                    elementDTOs,
                    categoryIds,
                },
            });

            const links = elementDTOs.map(element => element.links).flat().filter(link => link);
            yield put({
                type: applicationActionTypes.STORE_LINKS,
                payload: {links},
            });
        }
    }
};

export default loadCategories;
