import {findMatchingRoutePath, resolveRoute} from '@computerrock/formation-router';
import routePaths from '../routePaths';
import routesOrderingMapper from './routesOrderingMapper';

const resolvePreviousRoute = (location, searchParams) => {
    if (!location) return;

    const {pathname} = location;
    const routePath = findMatchingRoutePath(Object.values(routePaths), pathname);
    const previousRoutePath = Object.values(routesOrderingMapper).find(({currentPathName}) => (
        currentPathName === routePath
    ))?.previousPathName;

    return resolveRoute(previousRoutePath, {}, {search: searchParams});
};

export default resolvePreviousRoute;
