// import {topicTypes} from '@swiss-ski/sski-entity-types';
// import {kebabCase, snakeCase} from 'change-case';

/**
 * App config
 */
export default {
    // General
    VERSION: process.env.VERSION,
    BUILD: process.env.BUILD,
    PUBLIC_URL: process.env.PUBLIC_URL || '/',
    LOCALE: process.env.LOCALE || 'en',
    DEPLOYMENT_ENV: process.env.DEPLOYMENT_ENV,
    // Auth
    // COOKIE_DOMAIN: process.env.COOKIE_DOMAIN || undefined,
    // OIDC_SERVICE_URL: process.env.OIDC_SERVICE_URL,
    // OIDC_REALM: process.env.OIDC_REALM,
    // OIDC_CLIENT_ID: process.env.OIDC_CLIENT_ID,
    // OAUTH2_SERVICE_URL: process.env.OAUTH2_SERVICE_URL,
    // OAUTH2_CLIENT_ID: process.env.OAUTH2_CLIENT_ID,
    // Locale
    LOCALE_TRANSLATION_EN_US_URL: process.env.LOCALE_TRANSLATION_EN_US_URL,
    LOCALE_TRANSLATION_DE_DE_URL: process.env.LOCALE_TRANSLATION_DE_DE_URL,
    LOCALE_TRANSLATION_FR_FR_URL: process.env.LOCALE_TRANSLATION_FR_FR_URL,
    LOCALE_TRANSLATION_IT_IT_URL: process.env.LOCALE_TRANSLATION_IT_IT_URL,
    LOCALE_TRANSLATION_RM_RM_URL: process.env.LOCALE_TRANSLATION_RM_RM_URL,
    // API
    FTEM_STRAPI_API_BASE_URL: process.env.FTEM_STRAPI_API_BASE_URL || '',
    FTEM_STRAPI_API_BEARER_TOKEN: process.env.FTEM_STRAPI_API_BEARER_TOKEN || '',
    // App constants
    DEFAULT_CATEGORIES: ['sponsorship', 'developmentfocus', 'training-principles', 'endurance', 'power'],
};
