import {take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as applicationActionTypes from '../applicationActionTypes';
import modalIds from '../../modalIds';

const linkViewModalFlow = function* linkViewModalFlow() {
    while (true) {
        const {payload} = yield take(applicationActionTypes.OPEN_LINK_VIEW_MODAL);
        const {linkType, linkId} = payload;

        yield* openModal(modalIds.LINK_VIEW_MODAL, {linkType, linkId});

        yield take([applicationActionTypes.CLOSE_LINK_VIEW_MODAL]);
        yield* closeModal(modalIds.LINK_VIEW_MODAL, {linkType, linkId});
    }
};

export default linkViewModalFlow;
